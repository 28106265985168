import { Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  containerStyle: string;
  title: string;
  listItems: { id: number; link: string; name: string; icon?: ReactNode }[];
}

const FooterWidget: React.FC<Props> = (Props) => {
  return (
    <div className={Props.containerStyle}>
      <div className="footer-widget">
        <Typography
          variant="h6"
          style={{ color: "white", marginBottom: "14px" }}
        >
          {Props.title}
        </Typography>
        <div className="footer-widget-content">
          <ul style={{ padding: 0 }}>
            {Props.listItems.map((li) => (
              <li key={li.id}>
                <Typography
                  component="a"
                  className="footer-link"
                  variant="body2"
                  fontSize="14px"
                  href={li.link}
                  target="_blank"
                  color="#cdcdcd"
                  display="flex"
                  alignItems="center"
                  gap="10px"
                >
                  {li.icon}
                  {li.name}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterWidget;
