import React from "react";
import FooterWidget from "./footer-widget";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logo/white-logo.png";
import { Box, Typography } from "@mui/material";
import { Constants } from "../../constants";

interface Props {}

const Footer: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div
      // className="footer-section section"

      style={{
        backgroundColor: "#171621",
        color: "white",
        zIndex: "999",
        padding: "60px 0",
      }}
    >
      <div className="container">
        <div className="row gap-xs-5 ">
          <div className="col-xl-6 col-md-4 col-12">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              className="footer-widget"
            >
              <Box
                className="footer-logo"
                sx={{ marginBottom: { md: "3rem", xs: "1rem" } }}
              >
                <img src={Logo} alt="UH Training" width={100} />
              </Box>
              <Typography>
                {t("copyRight")} &copy; {new Date().getFullYear()}
              </Typography>
              {/* <Typography
                variant="h5"
                className="footer-widget-title"
                sx={{ color: "white" }}
              >
                {t("FooterAddress")} :
              </Typography> */}
              {/* <div className="footer-widget-content">
                <div className="content">
                  <Typography>
                    {" "}
                    شارع الإمام أبي حنيفة النعمان – دمشق – سوريا
                  </Typography>
                  <Typography>
                    <a href="mailto:info@example.com" className="footer-link">
                      info@uh-training.com
                    </a>
                  </Typography>
                </div>
                <div className="footer-social-inline">
                  <a
                    href="https://www.facebook.com/unlimitedhorizonssy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div> */}
            </Box>
          </div>
          <FooterWidget
            containerStyle="col-xl-3 col-md-4 col-sm-10 col-12 my-5 my-md-0"
            title={t("FooterWidgetInformation")}
            listItems={[
              { id: 5, link: "/about", name: t("NavHeaderAboutUs") },
              { id: 6, link: "/contact-us", name: t("locations") },
              { id: 2, link: "/terms-of-service", name: t("TOS") },
              {
                id: 3,
                link: "/privacy-and-terms",
                name: t("FootelListPrivacy"),
              },
              {
                id: 7,
                link: "#",
                name: t("generalMangerSpeech"),
              },
            ]}
          />
          <FooterWidget
            containerStyle="col-xl-3 col-md-4 col-sm-10 col-12"
            title={t("followUs")}
            listItems={[
              {
                id: 5,
                link: Constants.social.facebook,
                name: t("facebook"),
                icon: <i className="fab fa-facebook-square "></i>,
              },
              {
                id: 6,
                link: "/contact-us",
                name: t("x"),
                icon: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="#cdcdcd"
                    width={15}
                    height={15}
                  >
                    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                  </svg>
                ),
              },
              {
                id: 2,
                link: Constants.social.telegram,
                name: t("telegram"),
                icon: <i className="fab fa-telegram"></i>,
              },
              {
                id: 3,
                link: "/contact-us",
                name: t("youtube"),
                icon: <i className="fab fa-youtube"></i>,
              },
              {
                id: 3,
                link: Constants.social.instagram,
                name: t("instagram"),
                icon: <i className="fab fa-instagram"></i>,
              },
            ]}
          />
        </div>
        {/* <div className="row">
          <div className="col">
            <p className="copyright">
              &copy; {new Date().getFullYear()} UH Training. {t("FooterRights")}
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
