import React from "react";
import { Box } from "@mui/material";
import "../../../assets/CSS/homepage.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TopColoredCard from "../home-page-components/top-colored-card";
import {
  fetchTopCourses,
  fetchTopGlobalTests,
  fetchTopBlogs,
  fetchTopShopItems,
} from "../../../api/endpoints-functions";

interface Props {}

const TopCards: React.FC<Props> = () => {
  return (
    <div>
      <Box
        className="section "
        sx={{ padding: { xs: 0, md: "100px 60px " }, marginTop: { xs: 4 } }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "60px",
          }}
          className="colored"
          data-aos="fade-left"
        >
          <TopColoredCard
            type="course"
            path="/course-grid"
            color={"myorange"}
            endpoint="courses"
            endpointFn={fetchTopCourses}
          />
          <TopColoredCard
            endpoint="global-tests"
            type="test"
            path="/quizzes-grid"
            color="mypurple"
            endpointFn={fetchTopGlobalTests}
          />
          <TopColoredCard
            endpoint="blogs"
            type="blog"
            path="/blog-grid"
            color="myred"
            endpointFn={fetchTopBlogs}
          />
          <TopColoredCard
            endpoint="shop-items"
            type="shop-item"
            path="/shop"
            color={"mygreen"}
            endpointFn={fetchTopShopItems}
          />
        </Box>
      </Box>
    </div>
  );
};

export default TopCards;
