import React from "react";
import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import "../../assets/CSS/header.css";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import HeaderCart from "./header-cart";
import NavbarItem from "./navbar-item";
import { roleAtom, tokenAtom } from "./../../store";
import PersistentDrawerRight from "./header-mobile";
import { FaLinkedinIn, FaRegHeart } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { Box, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { PiGlobe } from "react-icons/pi";
import logo from "../../assets/images/logo/logo.svg";
import logoAr from "../../assets/images/logo/logo-ar.png";
import logoEn from "../../assets/images/logo/logo-en.png";
import i18next from "i18next";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../api/api";
import HeaderNotification from "./header-notification";
import { RiHeartLine } from "react-icons/ri";
import useDeviceType from "../../hooks/useDeviceType";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const [showMobileNav, setShowMobileNav] = React.useState(false);
  //@ts-ignore
  const { _1, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [token, setToken] = useAtom(tokenAtom);
  const [role, setRole] = useAtom(roleAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet, isLaptop, isLargeScreen } = useDeviceType();

  const {
    isLoading,
    mutate: logOut,
    isError,
  } = useMutation(
    (data: { device_id: string | null }) =>
      fetchApi("/auth/user/logout", "POST", data),
    {
      onSuccess: () => {
        setToken("");
        setRole("");
        localStorage.clear();
        navigate("/login");
      },
      onError: () => {
        setToken("");
        setRole("");
        localStorage.clear();
        navigate("/login");
      },
    }
  );

  const handleLogout = async () => {
    logOut({ device_id: localStorage.getItem("deviceId") });
  };
  const coursesArray = [
    {
      href: "/course-grid",
      linkName: t("NavHeaderCoursesGrid"),
    },
    {
      href: "/teachers", //TODO: create 'become-a-teacher' page
      linkName: t("NavHeaderBecome"),
    },
    {
      href: "/quizzes-grid",
      linkName: t("NavHeaderQuizzesGrid"),
    },
    {
      href: "/virtual-classroom",
      linkName: t("NavHeaderVRC"),
    },
  ];

  if (!token) {
    coursesArray.splice(3, 1);
  }

  return (
    <div
      className="header-section header-fluid sticky-header section"
      style={{ zIndex: 1000 }}
      dir={i18next.language === "ar" ? "rtl" : "ltr"}
    >
      <div
        className="header-inner"
        style={{ maxWidth: "100vw", paddingTop: "10px" }}
      >
        <Box
          className="position-relative container"
          sx={{
            width: "100%",
          }}
        >
          <Box
            className="row justify-content-center align-items-center justify-content-sm-between align-items-sm-end flex-nowrap"
            sx={{
              padding: {
                xs: "0px 0px 15px 20px",
                md: "0 0px 15px 20px",
                xl: "0 0 15px 0",
              },
              width: "100%",
            }}
          >
            <Box
              sx={{ width: { xs: "25vw", sm: "15vw", md: "10vw", xl: "22%" } }}
            >
              <Box
                // className="header-logo header__uhe__log"
                sx={{
                  height: "100%",
                  width: { xs: "100%", lg: "100%", xl: "100%" },
                }}
              >
                <Link to="/uh-home-page">
                  <img
                    src={
                      isMobile
                        ? logo
                        : i18next.language === "ar"
                        ? logoAr
                        : logoEn
                    }
                    alt="Learts Logo"
                    style={{
                      maxWidth: isMobile ? "100%" : "300px",
                    }}
                  />
                </Link>
              </Box>
            </Box>
            <Box
              className="col-10 col-xl-10 position-static"
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", xl: "row" },
                alignItems: { xs: "flex-end", xl: "center" },
                padding: { xs: 0 },
                justifyContent: "space-around",
              }}
            >
              <nav className="site-main-menu d-none d-lg-block">
                <ul
                  className="flex-nowrap"
                  style={{ justifyContent: "space-around", padding: "0" }}
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <NavbarItem
                    mainName={t("HeaderNavLearning")}
                    subLinksArr={coursesArray}
                  />
                  {role != "instructor" && (
                    <li style={{ whiteSpace: "nowrap" }}>
                      <Link to="/consultations">
                        <span className="menu-text">
                          <Typography>{t("consultations")} </Typography>
                        </span>
                      </Link>
                    </li>
                  )}

                  <NavbarItem
                    mainName={t("resourses")}
                    subLinksArr={[
                      {
                        href: "/blog-grid", //TODO: create 'shop' page
                        linkName: t("HeaderNavBlog"),
                      },
                      {
                        href: "/shop?type=1",
                        linkName: t("books"),
                      },
                      {
                        href: "/shop?type=2",
                        linkName: t("trainingTools"),
                      },
                    ]}
                  />
                  <NavbarItem
                    mainName={t("TOS")}
                    subLinksArr={[
                      {
                        href: "/terms-of-service",
                        linkName: t("TOS"),
                      },
                      {
                        href: "/privacy-and-terms",
                        linkName: t("NavHeaderPrivacy"),
                      },
                    ]}
                  />

                  <NavbarItem
                    mainName={t("NavHeaderContactUs")}
                    subLinksArr={[
                      {
                        href: "/about",
                        linkName: t("NavHeaderAboutUs"),
                      },
                      {
                        href: "/contact-us",
                        linkName: t("NavHeaderContactUs"),
                      },
                    ]}
                  />
                </ul>
              </nav>
              <Box
                className=""
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: { xs: "90%", sm: "auto" },
                }}
              >
                {/* <Box
                  sx={{
                    display: { xs: "none", lg: "flex" },
                    gap: "10px",
                    padding: "5px 20px",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Typography variant="h5" sx={{ color: "primary.main" }}>
                    {i18n.language === "en" ? "Follow Us:" : ": تابعنا"}
                  </Typography>
                  <Box className="header-social">
                    <FaLinkedinIn />
                  </Box>
                  <Box className="header-social">
                    <FaYoutube />
                  </Box>
                  <Box className="header-social">
                    <FaInstagram />
                  </Box>
                  <Box className="header-social">
                    <FaWhatsapp />
                  </Box>
                </Box> */}
                <Box className="header-right w-100">
                  <Box
                    className="inner flex-nowrap w-100 d-flex justify-content-between"
                    sx={{ gap: { xs: 1, sm: "15px" } }}
                  >
                    <Select
                      sx={{
                        display: { xs: "none", md: "block" },
                      }}
                      size="small"
                      MenuProps={{ disableScrollLock: true }}
                      value={i18n.language}
                      onChange={(event) => {
                        i18n.changeLanguage(event.target.value);
                        localStorage.setItem("lang", event.target.value);
                      }}
                      style={{ color: "#3f3a64", direction: "ltr" }}
                      IconComponent={PiGlobe}
                    >
                      <MenuItem value="en">
                        <Typography>EN</Typography>
                      </MenuItem>
                      <MenuItem value="ar">
                        <Typography style={{ textAlign: "left" }}>
                          AR
                        </Typography>
                      </MenuItem>
                    </Select>
                    {/* TODO: only for users*/}
                    {token.length ? (
                      <>
                        <HeaderCart />
                        <Tooltip title={t("favorites")}>
                          <Box
                            component="span"
                            width={isMobile ? "30px" : "45px"}
                            height={isMobile ? "25px" : "40px"}
                          >
                            <RiHeartLine
                              style={{
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("/wishlist")}
                              className="header-cart-btn"
                            />
                          </Box>
                        </Tooltip>

                        <HeaderNotification />

                        <Tooltip title={t("profile")}>
                          <div className="header-login">
                            <Link
                              to={`${
                                role === "student"
                                  ? "/student-profile"
                                  : "/profile"
                              }`}
                            >
                              <i
                                className="far fa-user-circle"
                                style={{ fontSize: "26px" }}
                              ></i>
                            </Link>
                          </div>
                        </Tooltip>
                        <Tooltip title={t("logout")}>
                          <div
                            className="header-logout"
                            onClick={() => handleLogout()}
                          >
                            <svg width="30" height="30" viewBox="0 0 24 24">
                              <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                              <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
                            </svg>
                          </div>
                        </Tooltip>
                      </>
                    ) : (
                      <Box
                        sx={{
                          marginLeft: {
                            xs: i18next.language == "en" ? "2em" : "0",
                            sm: "2em",
                          },
                          border: "1px solid",
                          borderRadius: "20px",
                          color: "primary.main",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="header-login">
                          <Link to="/login">
                            <Typography
                              fontSize="small"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {t("AuthFormLog")}
                            </Typography>
                          </Link>
                        </div>
                        <span>/</span>
                        <div className="header-login">
                          <Link to="/register">
                            <Typography
                              fontSize="small"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {t("LoginRegister")}
                            </Typography>
                          </Link>
                        </div>
                      </Box>
                    )}
                    {/* <HeaderSearch /> */}
                    <div className="header-mobile-menu-toggle d-lg-none ml-sm-2 sm:w-75">
                      <PersistentDrawerRight isLogin={!!token.length} />
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <hr
            style={{
              height: "1px",
              width: "100%",
              background: "#000",
              color: "#000",
              opacity: "1",
              margin: 0,
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default Header;
