import React, { useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import { IoMdNotificationsOutline } from "react-icons/io";

import NotificationsList from "./notifications-list";
import { fetchNotificationsNumber } from "../../api/endpoints-functions";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {}

const HeaderNotification: React.FC<Props> = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const miniCartRef = useRef(null);

  const { data, isLoading } = useQuery(["get-notifications-number"], () =>
    fetchNotificationsNumber()
  );

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        miniCartRef.current &&
        //@ts-ignore
        !miniCartRef.current.contains(event.target as Node) &&
        target.id !== "notification-id"
      ) {
        setIsModalVisible(false);
      }
    };

    // Add event listener when mini-cart is visible
    if (isModalVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    // Cleanup event listener when component is unmounted or mini-cart is hidden
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isModalVisible]);

  const toggleMiniCartVisibility = () => {
    setIsModalVisible((prev) => !prev);
  };

  const handelClick = () => {
    if (window.innerWidth > 768) {
      toggleMiniCartVisibility();
    } else {
      navigate("/notifications");
    }
  };

  const { t } = useTranslation();

  return (
    <Tooltip title={t("notifications")}>
      <div className="header-cart">
        <Box
          className="header-cart-btn"
          sx={{ cursor: "pointer" }}
          onClick={handelClick}
        >
          {data?.number! > 0 && (
            <span>
              {data ? (
                <span className="cart-count">
                  {data.number < 10 ? data.number : "+10"}
                </span>
              ) : (
                <span className="cart-count">
                  <CircularProgress sx={{ color: "#fff" }} size={6} />
                </span>
              )}
            </span>
          )}
          <IoMdNotificationsOutline size="large" id="notification-id" />
        </Box>
        {isModalVisible && (
          <div
            className="header-mini-cart course-scrollable-side"
            ref={miniCartRef}
          >
            <div className="inner">
              <div className="mini-cart-products" style={{ maxHeight: "45vh" }}>
                <NotificationsList setIsModalVisible={setIsModalVisible} />
              </div>
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default HeaderNotification;
